import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { ApiContext } from '../../context/apiConfig';

const BtncloLaporanForm = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const [errors, setErrors] = useState({
        judulLaporan: '',
        kategori: '',
        jenisLaporan: '',
        bidang: '',
        perihal: '',
        waktu: '',
        konten_1: '',
        konten_2: '',
        konten_3: '',
    });
    const [formData, setFormData] = useState({
        judulLaporan: '',
        kategori: '',
        jenisLaporan: '',
        bidang: '',
        perihal: '',
        waktu: '',
        konten_1: '',
        konten_2: '',
        konten_3: '',
    });
    const [images, setImages] = useState([]);
    const [kontenLabels, setKontenLabels] = useState({
        konten_1: 'Konten 1',
        konten_2: 'Konten 2',
        konten_3: 'Konten 3',
    });

    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    useEffect(() => {
        // Update konten labels based on kategori and jenisLaporan
        const getKontenLabels = (kategori, jenisLaporan) => {
            if (kategori === 'INSIDENTIL') {
                if (jenisLaporan === 'INFORMASI') {
                    return {
                        konten_1: 'Pendahuluan',
                        konten_2: 'Fakta-fakta',
                        konten_3: 'Rekomendasi',
                    };
                } else if (jenisLaporan === 'INFORMASI KHUSUS') {
                    return {
                        konten_1: 'Perihal',
                        konten_2: 'Fakta-fakta',
                        konten_3: 'Rekomendasi',
                    };
                }
            }
            return {
                konten_1: 'Laporan Kegiatan/Kronologis',
                konten_2: 'Hasil Kegiatan',
                konten_3: 'Rekomendasi',
            };
        };
    
        setKontenLabels(getKontenLabels(formData.kategori, formData.jenisLaporan));
    }, [formData.kategori, formData.jenisLaporan]); // Trigger when kategori or jenisLaporan changes

    useEffect(() => {
        // If kategori is INSIDENTIL and jenisLaporan is INFORMASI, set default value for konten_1
        if (formData.kategori === 'INSIDENTIL' && formData.jenisLaporan === 'INFORMASI') {
            setFormData(prevFormData => ({
                ...prevFormData,
                konten_1: `1. Sumber Informasi:\n2. Hubungan dengan Sumber:\n3. Cara Mendapatkan Informasi:\n4. Tempat dan Waktu Mendapatkan Informasi:`
            }));
        } else if (formData.kategori === 'INSIDENTIL' && formData.jenisLaporan !== 'INFORMASI') {
            // If jenisLaporan is not INFORMASI, clear konten_1
            setFormData(prevFormData => ({
                ...prevFormData,
                konten_1: '' // Clear konten_1 value when jenisLaporan is changed
            }));
        }
    }, [formData.kategori, formData.jenisLaporan]); // Trigger when kategori or jenisLaporan changes

    useEffect(() => {
        if (isEditMode) {
            const fetchLaporanData = async () => {
                try {
                    const response = await axios.get(`${baseURL}/api/v1/btnclo/laporan/${id}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    const laporan = response.data.laporan;
                    setFormData({
                        judulLaporan: laporan.judulLaporan || '',
                        kategori: laporan.kategori || '',
                        jenisLaporan: laporan.jenisLaporan || '',
                        bidang: laporan.bidang || '',
                        perihal: laporan.perihal || '',
                        waktu: laporan.waktu || '',
                        konten_1: laporan.konten_1 || '',
                        konten_2: laporan.konten_2 || '',
                        konten_3: laporan.konten_3 || '',
                    });
                    if (response.data.image && response.data.image.length > 0) {
                        setImages(response.data.image.map(img => ({
                            image_id: img.image_id,
                            id: img.id,
                            image: `${baseURL}${img.image}`,
                            isExisting: true
                        })));
                    }
                } catch (error) {
                    console.error('Error fetching laporan data:', error);
                }
            };

            fetchLaporanData();
        }
    }, [baseURL, id, isEditMode, token]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            const newImages = Array.from(files).map(file => ({
                file,
                preview: URL.createObjectURL(file),
                isExisting: false
            }));
            if (isEditMode) {
                setImages(newImages);
            } else {
                setImages(prevImages => [...prevImages, ...newImages]);
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation Check
        const newErrors = {};
        if (!formData.judulLaporan.trim()) newErrors.judulLaporan = 'Judul Kegiatan tidak boleh kosong';
        if (!formData.kategori.trim()) newErrors.kategori = 'Kategori Kegiatan tidak boleh kosong';
        if (!formData.jenisLaporan.trim()) newErrors.jenisLaporan = 'Jenis Kegiatan tidak boleh kosong';
        if (!formData.bidang.trim()) newErrors.bidang = 'Bidang tidak boleh kosong';
        if (!formData.perihal.trim()) newErrors.perihal = 'Perihal tidak boleh kosong';
        if (!formData.waktu.trim()) newErrors.waktu = 'Waktu Kegiatan tidak boleh kosong';
        if (!formData.konten_1.trim()) newErrors.konten_1 = `${kontenLabels.konten_1} tidak boleh kosong`;
        if (!formData.konten_2.trim()) newErrors.konten_2 = `${kontenLabels.konten_2} tidak boleh kosong`;
        if (!formData.konten_3.trim()) newErrors.konten_3 = `${kontenLabels.konten_3} tidak boleh kosong`;

        setErrors(newErrors);

        // If there are errors, scroll to the first one and stop
        const errorFields = Object.keys(newErrors);
        if (errorFields.length > 0) {
            const firstErrorField = errorFields[0];
            document.getElementById(firstErrorField)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        const apiEndpoint = isEditMode ? `${baseURL}/api/v1/btnclo/update_laporan/${id}` : `${baseURL}/api/v1/btnclo/store_laporan`;

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        images.forEach((image, index) => {
            if (image.file) {
                formDataToSend.append(`images[${index}]`, image.file);
            } else {
                formDataToSend.append(`existing_images[${index}]`, JSON.stringify(image));
            }
        });

        try {
            const response = await axios.post(apiEndpoint, formDataToSend, {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
            });
            console.log('Laporan saved successfully:', response.data);
            navigate(-1);
        } catch (error) {
            console.error('Error saving Laporan:', error.response ? error.response.data : error.message);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div className="mt-8">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="text-lg font-bold">Input Laporan</div>
                        <div className="flex flex-col gap-6 px-4">
                            <FormInput 
                                label="Judul Kegiatan (maksimal 140 karakter)" 
                                type="text" 
                                name="judulLaporan" 
                                value={formData.judulLaporan} 
                                handleChange={handleChange} 
                                errors={errors.judulLaporan} 
                                maxLength={140}
                            />
                            {/* Kategori is now read-only in edit mode */}
                            {isEditMode ? (
                                <div className="flex flex-col">
                                    <label className="block text-base leading-6 text-gray">Kategori Kegiatan</label>
                                    <input 
                                        type="text" 
                                        value={formData.kategori} 
                                        readOnly 
                                        id="kategori"
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black bg-gray-100 sm:text-sm sm:leading-6 ${errors.kategori ? 'ring-red' : ''}`}
                                    />
                                    {errors.kategori && <span className="text-red text-sm">{errors.kategori}</span>}
                                </div>
                            ) : (
                                <FormSelect 
                                    label="Kategori Kegiatan" 
                                    name="kategori" 
                                    value={formData.kategori} 
                                    handleChange={handleChange} 
                                    options={kategoriOptions} 
                                    errors={errors.kategori}
                                />
                            )}

                            {/* Jenis Laporan is now read-only in edit mode */}
                            {isEditMode ? (
                                <div className="flex flex-col">
                                    <label className="block text-base leading-6 text-gray">Jenis Kegiatan</label>
                                    <input 
                                        type="text" 
                                        value={formData.jenisLaporan} 
                                        readOnly 
                                        id="jenisLaporan"
                                        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black bg-gray-100 sm:text-sm sm:leading-6 ${errors.jenisLaporan ? 'ring-red' : ''}`}
                                    />
                                    {errors.jenisLaporan && <span className="text-red text-sm">{errors.jenisLaporan}</span>}
                                </div>
                            ) : (
                                <FormSelect 
                                    label="Jenis Kegiatan" 
                                    name="jenisLaporan" 
                                    value={formData.jenisLaporan} 
                                    handleChange={handleChange} 
                                    options={getJenisOptions(formData.kategori)} 
                                    errors={errors.jenisLaporan}
                                />
                            )}
                            <FormSelect 
                                label="Bidang" 
                                name="bidang" 
                                value={formData.bidang} 
                                handleChange={handleChange} 
                                options={bidangOptions} 
                                errors={errors.bidang}
                            />
                            <FormInput 
                                label="Perihal" 
                                type="text" 
                                name="perihal" 
                                value={formData.perihal} 
                                handleChange={handleChange} 
                                errors={errors.perihal}
                            />
                            <FormInput 
                                label="Waktu Kegiatan" 
                                type="date" 
                                name="waktu" 
                                value={formData.waktu} 
                                handleChange={handleChange} 
                                errors={errors.waktu}
                            />
                            <FormInput 
                                label={kontenLabels.konten_1} 
                                type="textarea" 
                                name="konten_1" 
                                value={formData.konten_1} 
                                handleChange={handleChange} 
                                readOnly={formData.kategori === 'INSIDENTIL' && formData.jenisLaporan === 'INFORMASI'} 
                                errors={errors.konten_1}
                            />
                            <FormInput 
                                label={kontenLabels.konten_2} 
                                type="textarea" 
                                name="konten_2" 
                                value={formData.konten_2} 
                                handleChange={handleChange}
                                errors={errors.konten_2}
                            />
                            <FormInput 
                                label={kontenLabels.konten_3} 
                                type="textarea" 
                                name="konten_3" 
                                value={formData.konten_3} 
                                handleChange={handleChange} 
                                errors={errors.konten_3}
                            />
                            {/* Image Upload Section */}
                            <div className="flex flex-col">
                                <label htmlFor="images" className="block text-base leading-6 text-gray">Foto Kegiatan (Jika ada)</label>
                                <div className="mt-2">
                                    <input
                                        type="file"
                                        name="images[]"
                                        id="images"
                                        multiple
                                        accept="image/*"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mt-4 flex flex-col gap-4">
                                    <div className="flex text-base">Preview Foto</div>
                                    <div className="flex flex-wrap gap-4">
                                        {images.map((image, index) => (
                                            <div key={index} className="relative">
                                                <img src={image.preview || image.image} alt="Preview" className="w-32 h-32 object-cover rounded-md" />
                                                <button 
                                                    type="button" 
                                                    onClick={() => handleRemoveImage(index)} 
                                                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row justify-end gap-3 mt-8">
                                <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                    Cancel
                                </button>
                                <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const FormInput = ({ label, type, name, value, handleChange, errors, maxLength, readOnly }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            {type === 'textarea' ? (
                <textarea
                    name={name}
                    id={name}
                    autoComplete="given-name"
                    className={`block h-[200px] w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 whitespace-pre-wrap ${errors ? 'ring-red' : ''}`}
                    value={value}
                    onChange={handleChange}
                    readOnly={readOnly}
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    id={name}
                    maxLength={maxLength}
                    autoComplete="given-name"
                    className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
                    value={value}
                    onChange={handleChange}
                    readOnly={readOnly}
                />
            )}
            {errors && <span className="text-red text-sm">{errors}</span>}
        </div>
    </div>
);

const FormSelect = ({ label, name, value, handleChange, options, errors }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <select
                id={name}
                name={name}
                autoComplete="given-name"
                className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
                value={value}
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
            {errors && <span className="text-red text-sm">{errors}</span>}
        </div>
    </div>
);

const kategoriOptions = [
    { value: '', label: 'Pilih Kategori' },
    { value: 'BERKALA', label: 'BERKALA' },
    { value: 'INSIDENTIL', label: 'INSIDENTIL' },
];

const bidangOptions = [
    { value: '', label: 'Pilih Bidang' },
    { value: 'IDEOLOGI', label: 'IDEOLOGI' },
    { value: 'POLITIK', label: 'POLITIK' },
    { value: 'EKONOMI', label: 'EKONOMI' },
    { value: 'SOSIAL', label: 'SOSIAL' },
    { value: 'BUDAYA', label: 'BUDAYA' },
];

const getJenisOptions = (kategori) => {
    if (kategori === 'BERKALA') {
        return [
            { value: '', label: 'Pilih Kegiatan' },
            { value: 'MINGGUAN', label: 'MINGGUAN' },
            { value: 'BULANAN', label: 'BULANAN' },
            { value: 'TAHUNAN', label: 'TAHUNAN' }
        ];
    } else if (kategori === 'INSIDENTIL') {
        return [
            { value: '', label: 'Pilih Kegiatan' },
            { value: 'INFORMASI', label: 'INFORMASI' },
            { value: 'INFORMASI KHUSUS', label: 'INFORMASI KHUSUS' },
        ];
    } else {
        return [{ value: '', label: 'Pilih Kategori Terlebih Dahulu' }];
    }
};

export default BtncloLaporanForm;
