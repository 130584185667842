import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';
import { ApiContext } from '../../context/apiConfig';

const LaporanForm = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;

    const [errors, setErrors] = useState({
        judulLaporan: ''
    });

    const [formData, setFormData] = useState({
        judulLaporan: '',
        kategori: '',
        jenisLaporan: '',
        waktu: '',
        konten: '',
        hasil: '',
        saran: '',
    });

    const [images, setImages] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    useEffect(() => {
        if (isEditMode) {
            const fetchLaporanData = async () => {
                try {
                    const response = await axios.get(`${baseURL}/api/laporan/${id}`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    const laporan = response.data.laporan;
                    setFormData({
                        judulLaporan: laporan.judulLaporan || '',
                        kategori: laporan.kategori || '',
                        jenisLaporan: laporan.jenisLaporan || '',
                        waktu: laporan.waktu || '',
                        konten: laporan.konten || '',
                        hasil: laporan.hasil || '',
                        saran: laporan.saran || '',
                    });
                    if (response.data.image && response.data.image.length > 0) {
                        setImages(response.data.image.map(img => ({
                            image_id: img.image_id,
                            id: img.id,
                            image: `${baseURL}${img.image}`,
                            isExisting: true
                        })));
                    }
                } catch (error) {
                    console.error('Error fetching laporan data:', error);
                }
            };

            fetchLaporanData();
        }
    }, [baseURL, id, isEditMode, token]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            const newImages = Array.from(files).map(file => ({
                file,
                preview: URL.createObjectURL(file),
                isExisting: false
            }));
            if (isEditMode) {
                setImages(newImages);
            } else {
                setImages(prevImages => [...prevImages, ...newImages]);
            }
        } else {
            if (name === 'judulLaporan') {
                if (value.length > 140) {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        judulLaporan: 'Judul Kegiatan tidak boleh lebih dari 140 karakter'
                    }));
                } else if (value.trim() === '') {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        judulLaporan: 'Judul Kegiatan tidak boleh kosong'
                    }));
                } else {
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        judulLaporan: ''
                    }));
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        [name]: value,
                        ...(name === 'kategori' && { jenisLaporan: '' }),
                    }));
                }
            } else {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [name]: value,
                    ...(name === 'kategori' && { jenisLaporan: '' }),
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.judulLaporan.trim() === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                judulLaporan: 'Judul Kegiatan tidak boleh kosong'
            }));
            return;
        }

        const apiEndpoint = isEditMode ? `${baseURL}/api/update_laporan/${id}` : `${baseURL}/api/store_laporan`;

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });

        images.forEach((image, index) => {
            if (image.file) {
                formDataToSend.append(`images[${index}]`, image.file);
            } else {
                formDataToSend.append(`existing_images[${index}]`, JSON.stringify(image));
            }
        });

        try {
            const response = await axios.post(apiEndpoint, formDataToSend, {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
            });
            console.log('Laporan saved successfully:', response.data);
            navigate(-1);
        } catch (error) {
            console.error('Error saving Laporan:', error.response ? error.response.data : error.message);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    return (
        <div className="mt-8">
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="text-lg font-bold">Input Laporan</div>
                        <div className="flex flex-col gap-6 px-4">
                            <FormInput 
                                label="Judul Kegiatan (maksimal 140 karakter)" 
                                type="text" 
                                name="judulLaporan" 
                                value={formData.judulLaporan} 
                                handleChange={handleChange} 
                                errors={errors.judulLaporan} 
                                maxLength={140} 
                            />
                            <FormSelect 
                                label="Kategori Kegiatan" 
                                name="kategori" 
                                value={formData.kategori} 
                                handleChange={handleChange} 
                                options={kategoriOptions} 
                            />
                            <FormSelect 
                                label="Jenis Kegiatan" 
                                name="jenisLaporan" 
                                value={formData.jenisLaporan} 
                                handleChange={handleChange} 
                                options={getJenisOptions(formData.kategori)} 
                            />
                            <FormInput 
                                label="Waktu Kegiatan" 
                                type="date" 
                                name="waktu" 
                                value={formData.waktu} 
                                handleChange={handleChange} 
                            />
                            <div className="flex flex-col">
                                <label htmlFor="images" className="block text-base leading-6 text-gray">Foto Kegiatan</label>
                                <div className="mt-2">
                                    <input
                                        type="file"
                                        name="images[]"
                                        id="images"
                                        multiple
                                        accept="image/*"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mt-4 flex flex-col gap-4">
                                    <div className="flex text-base">Preview Foto</div>
                                    <div className="flex flex-wrap gap-4">
                                        {images.map((image, index) => (
                                            <div key={index} className="relative">
                                                <img 
                                                    src={image.preview || image.image} 
                                                    alt={`Preview ${index}`} 
                                                    className="h-20 w-20 object-cover rounded-md"
                                                />
                                                {!isEditMode && (
                                                    <button 
                                                        type="button" 
                                                        onClick={() => handleRemoveImage(index)}
                                                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                                                    >
                                                        X
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <FormInput label="Laporan Kegiatan/Kronologis" type="textarea" name="konten" value={formData.konten} handleChange={handleChange} />
                            <FormInput label="Hasil Kegiatan" type="textarea" name="hasil" value={formData.hasil} handleChange={handleChange} />
                            <FormInput label="Saran dan Rekomendasi" type="textarea" name="saran" value={formData.saran} handleChange={handleChange} />
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Cancel
                            </button>
                            <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};



const FormInput = ({ label, type, name, value, handleChange, errors, maxLength }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            {type === 'textarea' ? (
                <textarea
                    name={name}
                    id={name}
                    autoComplete="given-name"
                    className={`block h-[200px] w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 whitespace-pre-wrap ${errors ? 'ring-red' : ''}`}
                    value={value}
                    onChange={handleChange}
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    id={name}
                    maxLength={maxLength}
                    autoComplete="given-name"
                    className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
                    value={value}
                    onChange={handleChange}
                />
            )}
            {errors && <span className="text-red text-sm">{errors}</span>}
        </div>
    </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <select
                id={name}
                name={name}
                autoComplete="given-name"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                value={value}
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    </div>
);

const kategoriOptions = [
    { value: '', label: 'Pilih Kategori' },
    { value: 'BIASA', label: 'BIASA' },
    { value: 'KHUSUS', label: 'KHUSUS' },
];

const getJenisOptions = (kategori) => {
    if (kategori === 'BIASA') {
        return [
            { value: '', label: 'Pilih Kegiatan' },
            { value: 'PATROLI', label: 'PATROLI' },
            { value: 'GIAT PENGAMANAN', label: 'GIAT PENGAMANAN' },
            { value: 'SAMBANG SOSIALISASI', label: 'SAMBANG SOSIALISASI' },
            { value: 'GIAT SINERGITAS DENGAN SATKER/INSTANSI TERKAIT', label: 'GIAT SINERGITAS DENGAN SATKER/INSTANSI TERKAIT' },
        ];
    } else if (kategori === 'KHUSUS') {
        return [
            { value: '', label: 'Pilih Kegiatan' },
            { value: 'PENYELUNDUPAN ORANG', label: 'PENYELUNDUPAN ORANG' },
            { value: 'PENYELUNDUPAN SEMBAKO', label: 'PENYELUNDUPAN SEMBAKO' },
            { value: 'PENYELUNDUPAN SATWA', label: 'PENYELUNDUPAN SATWA' },
            { value: 'PENYELUNDUPAN HASIL BUMI', label: 'PENYELUNDUPAN HASIL BUMI' },
            { value: 'PENYELUNDUPAN SENJATA DAN BAHAN PELEDAK', label: 'PENYELUNDUPAN SENJATA DAN BAHAN PELEDAK' },
            { value: 'NARKOBA', label: 'NARKOBA' },
            { value: 'PENCURIAN IKAN (ILLEGAL FISHING)', label: 'PENCURIAN IKAN (ILLEGAL FISHING)' },
            { value: 'PELINTAS BATAS ILEGAL', label: 'PELINTAS BATAS ILEGAL' },
            { value: 'TERORISME', label: 'TERORISME' },
            { value: 'TINDAK PIDANA LAINNYA', label: 'TINDAK PIDANA LAINNYA' },
        ];
    } else {
        return [{ value: '', label: 'Pilih Kategori Terlebih Dahulu' }];
    }
};

export default LaporanForm;
