import axios from 'axios';

let BASE_URL;

if (window.location.hostname === "localhost") {
    BASE_URL = 'https://imbascepat.divhubinter.polri.go.id/api';
} else {
    BASE_URL = 'https://imbascepat.divhubinter.polri.go.id/api';
}

export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true // Include credentials with requests
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

