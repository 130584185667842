import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const AdminUser = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(true);

    const fetchUsers = useCallback(async (page = 1, search = '') => {
        setLoading(true);
        try {
            let endpoint = '';
    
            if (search) {
                // For search, include both search term and page number in the query
                endpoint = `/api/search_user/${search}?page=${page}`;
            } else {
                // For pagination without search
                endpoint = `/api/list_user?page=${page}`;
            }
    
            // Construct the full URL
            const url = `${baseURL}${endpoint}`;
            console.log(`Fetching users from: ${url}`); // Debug statement
    
            // Make the API request
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
    
            // Conditional assignment based on search
            let userData;
            if (search) {
                userData = response.data.user;
            } else {
                userData = response.data.users;
            }
    
            setUsers(userData.data);
            setPaginationLinks(userData.links);
            setCurrentPage(userData.current_page);
            setTotalPages(userData.last_page);
            console.log('User data fetched successfully', userData); // Debug statement
        } catch (error) {
            console.error('There was an error fetching the user data!', error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, token]);
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchUsers(1, searchKeyword);
        }, 300);
    
        return () => clearTimeout(delayDebounceFn);
    }, [fetchUsers, searchKeyword]);

    const deleteUser = useCallback((userId) => {
        axios.delete(`${baseURL}/api/delete_user/${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(() => {
            fetchUsers(currentPage, searchKeyword);
        })
        .catch(error => {
            console.error('There was an error deleting the user!', error);
        });
    }, [token, baseURL, currentPage, fetchUsers, searchKeyword]);

    const handleEditClick = (userId) => {
        navigate(`/admin/edituser/${userId}`);
    };

    const handleDeleteClick = (userId) => {
        setSelectedUserId(userId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deleteUser(selectedUserId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            fetchUsers(page, searchKeyword);
        }
    };

    const getAccessLevelColor = (accessLevel) => {
        switch (accessLevel) {
            case '0': return 'bg-gray-dark ';
            case '1': return 'bg-red-dark';
            case '2': return 'bg-yellow-dark';
            case '3': return 'bg-green-dark';
            case '4': return 'bg-blue';
            case '5': return 'bg-blue-light';
            default: return 'bg-gray-dark';
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Manajemen User</div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-row w-full justify-between">
                            <div className="pb-4 bg-white text-black-light">
                                <AccessLevelGuide />
                            </div>
                            <Link to="/admin/adduser" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                                <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4" />
                                <div className="hidden laptop:flex">Input User</div>
                            </Link>
                        </div>
                        <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
                        <div className="text-base">
                            {loading ? (
                                <div className="text-center py-4">Loading data...</div>
                            ) : (
                                <UserTable 
                                    users={users} 
                                    handleEditClick={handleEditClick} 
                                    handleDeleteClick={handleDeleteClick} 
                                    getAccessLevelColor={getAccessLevelColor} 
                                    currentPage={currentPage}
                                />
                            )}
                        </div>
                        {renderPagination()}
                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

const AccessLevelGuide = () => (
    <div className="flex flex-col gap-3 relative">
        <div>Panduan Access Level:</div>
        <div className="flex flex-row gap-2 items-center">
            <div className="flex h-8 w-8 p-2 justify-center items-center rounded-full bg-red-dark">
                <span className="text-white">1</span>
            </div>
            <div className="font-mp-bold">ADMIN</div>
        </div>
        <div className="flex flex-row gap-2 items-center">
            <div className="flex h-8 w-8 p-2 justify-center items-center rounded-full bg-yellow-dark">
                <span className="text-white">2</span>
            </div>
            <div className="font-mp-bold">VVIP</div>
        </div>
        <div className="flex flex-row gap-2 items-center">
            <div className="flex h-8 w-8 p-2 justify-center items-center rounded-full bg-green-dark">
                <span className="text-white">3</span>
            </div>
            <div className="font-mp-bold">USER</div>
        </div>
        <div className="flex flex-row gap-2 items-center">
            <div className="flex h-8 w-8 p-2 justify-center items-center rounded-full bg-blue">
                <span className="text-white">4</span>
            </div>
            <div className="font-mp-bold">BTNCLO KOORDINATOR</div>
        </div>
        <div className="flex flex-row gap-2 items-center">
            <div className="flex h-8 w-8 p-2 justify-center items-center rounded-full bg-blue-light">
                <span className="text-white">5</span>
            </div>
            <div className="font-mp-bold">BTNCLO ANGGOTA</div>
        </div>
    </div>
);

const SearchBar = ({ searchKeyword, setSearchKeyword }) => (
    <div className="flex w-1/2 pt-4 pb-2 bg-white text-black-light">
        <div className="flex flex-col relative mt-1 gap-4">
            <input 
                type="text" 
                id="search" 
                placeholder="Search..." 
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="block w-full rounded-md border-0 px-2 py-1 mr-20 shadow-sm ring-1 ring-black-light placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-black-light sm:text-sm sm:leading-6" 
            />
        </div>
    </div>
);

const UserTable = ({ users, handleEditClick, handleDeleteClick, getAccessLevelColor, currentPage }) => (
    <div className="mt-6 text-base">
        <div className="min-w-full overflow-x-auto">
            <div className="overflow-y-auto">
                <table className="min-w-full table-auto divide-y divide-gray-dark">
                    <thead className="bg-gray-light">
                        <tr>
                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">USERNAME</th>
                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">FULLNAME</th>
                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">POSITION</th>
                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACCESS LEVEL</th>
                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-dark">
                        {users.length > 0 ? (
                            users.map((user, index) => (
                                <tr key={user.id}>
                                    <td className="px-5 py-2 whitespace-normal">{(currentPage - 1) * users.length + index + 1}</td>
                                    <td className="px-5 py-2 whitespace-normal">{user.username}</td>
                                    <td className="px-5 py-2 whitespace-normal">{user.name}</td>
                                    <td className="px-5 py-2 whitespace-normal">{user.instansi}</td>
                                    <td className="px-5 py-2 whitespace-normal">
                                        <div className={`flex h-8 w-8 p-2 justify-center items-center rounded-full ${getAccessLevelColor(user.access_level)}`}>
                                            <span className="text-white">{user.access_level}</span>
                                        </div>
                                    </td>
                                    <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                        <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto" onClick={() => handleEditClick(user.id)}>
                                            <FontAwesomeIcon icon={faEdit} className="text-white h-4 w-4" />
                                        </button>
                                        <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto" onClick={() => handleDeleteClick(user.id)}>
                                            <FontAwesomeIcon icon={faTrash} className="text-white h-4 w-4" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="text-center py-4">
                                    No data available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);


const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
            <div className="flex flex-row justify-between">
                <button
                    onClick={handleConfirmDelete}
                    className="bg-green text-white px-4 py-2 rounded-lg"
                >
                    Ya
                </button>
                <button
                    onClick={handleCancelDelete}
                    className="bg-red text-white px-4 py-2 rounded-lg"
                >
                    Tidak
                </button>
            </div>
        </div>
    </div>
);

export default AdminUser;
