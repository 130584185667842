import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../api/axios';

const PoldaBtncloForm = () => {
    const baseURL = useContext(ApiContext);
    const [formData, setFormData] = useState({ polda: '', sprin: null });
    const [sprinPreview, setSprinPreview] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});

    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            setIsEditMode(true);
            const fetchBtncloData = async () => {
                try {
                    const response = await axios.get(`${baseURL}/api/v1/btnclo/polda/${id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    const btncloData = response.data.polda;
                    setFormData({
                        polda: btncloData.polda || '',
                        sprin: null,
                    });
                    setSprinPreview(btncloData.sprin || '');
                } catch (error) {
                    console.error('Error fetching POLDA BTNCLO data:', error);
                }
            };

            fetchBtncloData();
        }
    }, [id, token, baseURL]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'sprin') {
            const file = files[0];
            if (file && file.type !== 'application/pdf') {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    sprin: 'Only PDF files are allowed.',
                }));
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    sprin: null,
                }));
                setSprinPreview('');
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    sprin: '',
                }));
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    sprin: file,
                }));
                setSprinPreview(file ? URL.createObjectURL(file) : '');
            }
        } else if (name === 'polda') {
            if (!value.includes('BTNCLO')) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    polda: 'The POLDA name must include "BTNCLO".',
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    polda: '',
                }));
            }
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (errors.polda || !formData.polda.includes('BTNCLO')) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                polda: 'The POLDA name must include "BTNCLO".',
            }));
            return;
        }

        const apiEndpoint = isEditMode ? `${baseURL}/api/v1/btnclo/update_polda/${id}` : `${baseURL}/api/v1/btnclo/store_polda`;

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('polda', formData.polda);
            if (formData.sprin) {
                formDataToSend.append('sprin', formData.sprin);
            }

            const response = await axios.post(apiEndpoint, formDataToSend, {
                headers: { 
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('POLDA BTNCLO saved successfully:', response.data);
            navigate('/admin/adminpoldabtnclo');
        } catch (error) {
            console.error('Error saving POLDA BTNCLO:', error.response ? error.response.data : error.message);
        }
    };

    const handleCancel = () => {
        navigate('/admin/adminpoldabtnclo');
    };

    return (
        <div className="mt-8">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
                        <div className="text-lg font-bold">{isEditMode ? 'Edit BTNCLO' : 'Input BTNCLO'}</div>
                        <div className="flex flex-col gap-6 px-4">
                            <FormInput 
                                label="POLDA (Wajib menulis BTNCLO di depan)" 
                                type="text" 
                                name="polda" 
                                value={formData.polda} 
                                handleChange={handleChange} 
                                errors={errors.polda} 
                            />
                            <FormInput 
                                label="Sprin File (PDF)" 
                                type="file" 
                                name="sprin" 
                                value="" 
                                handleChange={handleChange} 
                                accept=".pdf" 
                                errors={errors.sprin}
                            />
                            {sprinPreview && (
                                <div className="mt-4 flex flex-col gap-4">
                                    <div className="text-base">Preview PDF</div>
                                    <div className="flex items-center gap-4">
                                        <embed
                                            src={sprinPreview}
                                            type="application/pdf"
                                            className="w-full h-64 border border-gray-dark rounded-md"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row justify-end gap-3 mt-8">
                            <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                                Cancel
                            </button>
                            <button 
                                type="submit" 
                                className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg"
                                disabled={!!errors.polda || !!errors.sprin}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

const FormInput = ({ label, type, name, value, handleChange, accept, errors }) => (
    <div className="flex flex-col">
        <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
        <div className="mt-2">
            <input
                type={type}
                name={name}
                id={name}
                autoComplete="off"
                className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-dark focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                value={type === 'file' ? undefined : value}
                onChange={handleChange}
                accept={accept}
            />
        </div>
        {errors && <div className="text-red-500 text-sm mt-1">{errors}</div>}
    </div>
);

export default PoldaBtncloForm;
