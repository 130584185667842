import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { Link, useNavigate } from 'react-router-dom';

const AdminPoldaBtnclo = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [btnclo, setBtnclo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedBtncloId, setSelectedBtncloId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [loading, setLoading] = useState(true);

    // Fetch btnclo data with optional search keyword
    const fetchBtnclo = useCallback(async (page = 1, search = '') => {
        setLoading(true);
        try {
            const endpoint = search
                ? `/api/v1/btnclo/search_polda/${search}?page=${page}`
                : `/api/v1/btnclo/list_polda?page=${page}`;

            const url = `${baseURL}${endpoint}`;
            console.log(`Fetching btnclo data from: ${url}`);

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const btncloData = response.data.polda; // Adjusted to use "polda" from the payload
            setBtnclo(btncloData.data);
            setPaginationLinks(Array.from({ length: btncloData.last_page }, (_, i) => ({
                label: i + 1,
                active: btncloData.current_page === i + 1,
            })));
            setCurrentPage(btncloData.current_page);
            setTotalPages(btncloData.last_page);
            console.log('Btnclo data fetched successfully', btncloData);
        } catch (error) {
            console.error('There was an error fetching the btnclo data!', error);
        } finally {
            setLoading(false);
        }
    }, [baseURL, token]);

    // Debounced effect to fetch data on search keyword change
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchBtnclo(1, searchKeyword);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [fetchBtnclo, searchKeyword]);

    const deleteBtnclo = useCallback((btncloId) => {
        axios.delete(`${baseURL}/api/v1/btnclo/delete_polda/${btncloId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(() => {
            fetchBtnclo(currentPage, searchKeyword); // Refresh data after deletion
        })
        .catch(error => {
            console.error('There was an error deleting the btnclo!', error);
        });
    }, [token, baseURL, currentPage, fetchBtnclo, searchKeyword]);

    const handleEditClick = (btncloId) => {
        navigate(`/admin/editpoldabtnclo/${btncloId}`);
    };

    const handleDeleteClick = (btncloId) => {
        setSelectedBtncloId(btncloId);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        deleteBtnclo(selectedBtncloId);
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            fetchBtnclo(page, searchKeyword);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks.map((link, index) => (
                <button
                    key={index}
                    onClick={() => handlePageChange(link.label)}
                    className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${
                        link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'
                    }`}
                >
                    {link.label}
                </button>
            ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Manajemen POLDA BTNCLO</div>
            <div className="mt-8">
                <div className="bg-white h-full px-6 py-6 border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                    <div className="flex flex-row w-full justify-between">
                        <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
                        <Link to="/admin/addpoldabtnclo" className="bg-blue hover:bg-green text-[white] flex justify-between items-center h-10 w-auto p-4 gap-2 rounded-lg">
                            <FontAwesomeIcon icon={faPlus} className="text-white h-4 w-4" />
                            <div className="hidden laptop:flex">Input POLDA BTNCLO</div>
                        </Link>
                    </div>
                    {loading ? (
                        <div className="text-center py-4">Loading data...</div>
                    ) : (
                        <BtncloTable 
                            btnclo={btnclo}
                            handleEditClick={handleEditClick} 
                            handleDeleteClick={handleDeleteClick}
                        />
                    )}
                    {renderPagination()}
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    handleConfirmDelete={handleConfirmDelete}
                    handleCancelDelete={handleCancelDelete}
                />
            )}
        </div>
    );
};

const SearchBar = ({ searchKeyword, setSearchKeyword }) => (
    <div className="flex w-1/2 pt-4 pb-2 bg-white text-black-light">
        <div className="flex flex-col relative mt-1 gap-4">
            <input 
                type="text" 
                id="search" 
                placeholder="Search..." 
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="block w-full rounded-md border-0 px-2 py-1 mr-20 shadow-sm ring-1 ring-black-light placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-black-light sm:text-sm sm:leading-6" 
            />
        </div>
    </div>
);

const BtncloTable = ({ btnclo, handleEditClick, handleDeleteClick }) => (
    <div className="mt-6 text-base">
        <table className="min-w-full table-auto divide-y divide-gray-dark">
            <thead className="bg-gray-light">
                <tr>
                    <th className="px-5 py-2 text-gray-500 uppercase text-left">NO</th>
                    <th className="px-5 py-2 text-gray-500 uppercase text-left">POLDA</th>
                    <th className="px-5 py-2 text-gray-500 uppercase text-left">ACTION</th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-dark">
                {btnclo.length > 0 ? (
                    btnclo.map((item, index) => (
                        <tr key={item.id}>
                            <td className="px-5 py-2">{index + 1}</td>
                            <td className="px-5 py-2">{item.polda}</td>
                            <td className="px-5 py-2 flex gap-2">
                                <button onClick={() => handleEditClick(item.id)} className="bg-yellow p-2 rounded-lg">
                                    <FontAwesomeIcon icon={faEdit} className="text-white" />
                                </button>
                                <button onClick={() => handleDeleteClick(item.id)} className="bg-red p-2 rounded-lg">
                                    <FontAwesomeIcon icon={faTrash} className="text-white" />
                                </button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" className="text-center py-4">No data available.</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
);

const DeleteConfirmationModal = ({ handleConfirmDelete, handleCancelDelete }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
            <div className="flex flex-row justify-between">
                <button
                    onClick={handleConfirmDelete}
                    className="bg-green text-white px-4 py-2 rounded-lg"
                >
                    Ya
                </button>
                <button
                    onClick={handleCancelDelete}
                    className="bg-red text-white px-4 py-2 rounded-lg"
                >
                    Tidak
                </button>
            </div>
        </div>
    </div>
);

export default AdminPoldaBtnclo;
