import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ApexCharts from 'react-apexcharts';

const BtncloDetail = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();
    const location = useLocation();

    const btncloData = decodeURIComponent(location.pathname.split("/btnclo/")[1]) || location.state?.btncloData || "";
    const cleanedPoldaName = encodeURIComponent(btncloData);

    const [logo, setLogo] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [chartData, setChartData] = useState({
        laporanBiasa: { Mingguan: 0, Bulanan: 0, Tahunan: 0 },
        laporanKhusus: { Informasi: 0, Khusus: 0 },
        laporanTotal: 0,
        biasaTotal: 0,
        khususTotal: 0,
    });
    const [personelData, setPersonelData] = useState([]);
    const [sprin, setSprin] = useState("");
    const [laporanData, setLaporanData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);
    const itemsPerPage = 20;

    const fetchData = useCallback(async (page = 1) => {
        try {
            const response = await axios.get(`${baseURL}/api/v1/btnclo/btnclo_details/${cleanedPoldaName}?page=${page}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const {
                polda,
                personel,
                laporan,
                chart_berkala,
                chart_insidentil,
                laporan_total,
                laporan_berkala,
                laporan_insidentil,
            } = response.data;

            if (polda && polda.length > 0) {
                setLogo(polda[0].logo || "");
                setSprin(polda[0].sprin || "");
            } else {
                setLogo("");
                setSprin("");
            }

            setPersonelData(personel || []);

            setChartData({
                laporanBiasa: chart_berkala || { Mingguan: 0, Bulanan: 0, Tahunan: 0 },
                laporanKhusus: chart_insidentil || { Informasi: 0, Khusus: 0 },
                laporanTotal: laporan_total || 0,
                biasaTotal: laporan_berkala || 0,
                khususTotal: laporan_insidentil || 0,
            });

            setLaporanData(laporan?.data || []);
            setPaginationLinks(laporan?.links || []);
            setCurrentPage(laporan?.current_page || 1);
            setTotalPages(laporan?.last_page || 1);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [baseURL, token, cleanedPoldaName]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const toUpperCase = (str) => {
        return str ? str.toUpperCase() : '';
    };

    const handleEditClick = (id, type) => {
        navigate(`/admin/editpersonelbtnclo/${id}`);
    };

    const handleDeleteClick = (id, type) => {
        setSelectedItemId(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${baseURL}/api/v1/btnclo/delete_personel/${selectedItemId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchData(); // Refresh data after deletion
        } catch (error) {
            console.error('Error deleting item:', error);
        }
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2 flex-wrap">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg flex items-center justify-center ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg flex items-center justify-center"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    return (
        <div className="">
            <div className="font-mp-bold text-xl text-gray-light">{btncloData}</div>
            <div className="mt-10">
                <div className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-4">
                    <div className="overflow-hidden rounded-lg bg-white laptop:h-full desktop:h-full border border-gray-dark shadow-lg shadow-gray-box col-span-2">
                        <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                            <div className="text-lg font-mp-bold justify-start">
                                Laporan
                            </div>
                            <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Berkala</div>
                                    <LaporanBiasa
                                        laporanBiasa={chartData.laporanBiasa}
                                        laporanBiasaTotal={chartData.biasaTotal}
                                    />
                                </div>
                                <div className="flex flex-col gap-10 h-full justify-between items-center text-center font-mp-bold">
                                    <div className="flex items-center text-start justify-center">
                                        <img
                                            src={logo ? `${baseURL}${logo.startsWith('/') ? logo : '/' + logo}` : logo}
                                            alt="logo"
                                            width={200}
                                            height={278}
                                            sizes="100vw"
                                            className="flex aspect-[16/9] rounded-xl object-contain"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                        <div className="text-xl">{chartData.laporanTotal}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Insidentil</div>
                                    <LaporanKhusus
                                        laporanKhusus={chartData.laporanKhusus}
                                        laporanKhususTotal={chartData.khususTotal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">Surat Perintah</div>
                        </div>
                        <div className="mt-6 text-sm">
                            <div className="min-w-full overflow-x-auto">
                                {sprin ? (
                                    <iframe
                                        src={`${baseURL}${sprin.startsWith('/') ? sprin : '/' + sprin}`}
                                        title="PDF Preview"
                                        className="w-full h-screen"
                                        style={{ minHeight: '500px' }}
                                    >
                                        Your browser does not support PDFs. <a href={`${baseURL}${sprin.startsWith('/') ? sprin : '/' + sprin}`}>Download the PDF</a>.
                                    </iframe>
                                ) : (
                                    <div>No SPRIN available</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">Koordinator & Anggota</div>
                        </div>
                        <div className="mt-6 text-sm">
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                    <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NAMA</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JABATAN</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JABATAN POLRI</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">KONTAK</th>
                                                {
                                                    auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                        <>
                                                            <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                                        </>
                                                    ) : null
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
                                            {personelData.map((person, index) => (
                                                <tr key={person.id}>
                                                    <td className="px-5 py-2 whitespace-normal">{index + 1}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{person.nama}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{person.role}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{person.jabatan}</td>
                                                    <td className="px-5 py-2 whitespace-normal">{person.kontak}</td>
                                                    {
                                                        auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 ? (
                                                            <>
                                                            <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                                                                <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleEditClick(person.id, 'personel')}
                                                                    />
                                                                </button>
                                                                <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className="text-white h-4 w-4"
                                                                        onClick={() => handleDeleteClick(person.id, 'personel')}
                                                                    />
                                                                </button>
                                                            </td>
                                                            </>
                                                        ) : null
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col col-span-2 bg-white px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        <div className="flex flex-row justify-between">
                            <div className="text-lg font-mp-bold">Laporan</div>
                        </div>
                        <div className="mt-6 text-sm">
                            <div className="min-w-full overflow-x-auto">
                                <div className="overflow-y-auto">
                                <table className="min-w-full table-auto divide-y divide-gray-dark">
                                        <thead className="bg-gray-light">
                                            <tr>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">NO</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">JUDUL LAPORAN</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">BTNCLO</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">KATEGORI</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">WAKTU DIBUAT</th>
                                                <th className="px-5 py-2 text-gray-500 uppercase tracking-wider text-left">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-dark">
    {laporanData.map((laporan, index) => (
        <tr key={laporan.id}>
            <td className="px-5 py-2 whitespace-normal">
                {(currentPage - 1) * itemsPerPage + index + 1}
            </td>
            <td className="px-5 py-2 whitespace-normal">
                <Link to={`/btnclolaporan/${laporan.id}`}>
                    {toUpperCase(laporan.judulLaporan)}
                </Link>
            </td>
            <td className="px-5 py-2 whitespace-normal">{laporan.btnclo}</td>
            <td className="px-5 py-2 whitespace-normal">{laporan.kategori}</td>
            <td className="px-5 py-2 whitespace-normal">{laporan.waktu}</td>
            {auth?.user?.access_level >= 0 && auth?.user?.access_level <= 1 && (
                <td className="px-5 py-2 whitespace-normal flex flex-row gap-3">
                    <button className="flex justify-center items-center rounded-lg bg-yellow p-2 w-auto">
                        <FontAwesomeIcon
                            icon={faEdit}
                            className="text-white h-4 w-4"
                            onClick={() => handleEditClick(laporan.id, 'personel')}
                        />
                    </button>
                    <button className="flex justify-center items-center rounded-lg bg-red p-2 w-auto">
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="text-white h-4 w-4"
                            onClick={() => handleDeleteClick(laporan.id, 'personel')}
                        />
                    </button>
                </td>
            )}
        </tr>
    ))}
</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {renderPagination()}
                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteConfirmationModal
                    onConfirm={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                />
            )}
        </div>
    );
};

const DeleteConfirmationModal = ({ onConfirm, onCancel }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-box bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-lg font-semibold mb-4">Apakah anda ingin menghapus data ini?</div>
            <div className="flex flex-row justify-between">
                <button
                    onClick={onConfirm}
                    className="bg-green text-white px-4 py-2 rounded-lg"
                >
                    Ya
                </button>
                <button
                    onClick={onCancel}
                    className="bg-red text-white px-4 py-2 rounded-lg"
                >
                    Tidak
                </button>
            </div>
        </div>
    </div>
);


const LaporanBiasa = ({ laporanBiasa, laporanBiasaTotal }) => {
    const defaultData = {
        labels: ['Mingguan', 'Bulanan', 'Tahunan'],
        series: [0, 0, 0],
    };

    const mappedData = laporanBiasa || defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBiasaChart laporanBiasa={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBiasaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBiasaChart = ({ laporanBiasa }) => {
    const { labels = [], series = [] } = laporanBiasa || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04'],
        labels: labels,
        chart: { type: 'donut' },
        dataLabels: { enabled: false },
        legend: { show: false },
        plotOptions: { donut: { size: '85%' } },
        responsive: [{ breakpoint: 480, options: { chart: { width: 200 } } }],
    };

    const chartSeries = series.length > 0 ? series[0].data : [];

    return <ApexCharts options={options} series={chartSeries} type="donut" />;
};

const LaporanKhusus = ({ laporanKhusus, laporanKhususTotal }) => {
    const defaultData = {
        labels: ['Informasi', 'Khusus'],
        series: [0, 0],
    };

    const mappedData = laporanKhusus || defaultData;

    const labels = Object.keys(mappedData);
    const series = [{ data: Object.values(mappedData) }];

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanKhususChart laporanKhusus={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanKhususTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanKhususChart = ({ laporanKhusus }) => {
    const { labels = [], series = [] } = laporanKhusus || {};

    const options = {
        colors: ['#DC2626', '#CA8A04'],
        labels: labels,
        chart: { type: 'donut' },
        dataLabels: { enabled: false },
        legend: { show: false },
        plotOptions: { donut: { size: '85%' } },
        responsive: [{ breakpoint: 480, options: { chart: { width: 200 } } }],
    };

    const chartSeries = series.length > 0 ? series[0].data : [];

    return <ApexCharts options={options} series={chartSeries} type="donut" />;
};

export default BtncloDetail;
