import React from 'react';

const PanduanBtnclo = () => {

    const pdfUrl = `${process.env.PUBLIC_URL}/files/Guidebook BTNCLO.pdf`;

    return (
        <div className="h-full">
            {/* PDF Preview */}
            <iframe 
                src={pdfUrl}
                title="PDF Preview"
                className="w-full h-screen"
                style={{ minHeight: '500px' }}
            >
                Your browser does not support PDFs. <a href={pdfUrl}>Download the PDF</a>.
            </iframe>
        </div>
    );
}

export default PanduanBtnclo;
