import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import ApexCharts from 'react-apexcharts';
import { ApiContext } from '../context/apiConfig';
import AuthContext from '../context/AuthProvider';
import axios from '../api/axios';
import Card from '../components/reusable/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { formatDistanceToNow } from 'date-fns';
import { id } from 'date-fns/locale';

const HomeBtnclo = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const userAccessLevel = auth?.user?.access_level;
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [chartData, setChartData] = useState({
        laporanBerkala: [],
        laporanInsidentil: [],
        laporanTotal: 0,
        berkalaTotal: 0,
        insidentilTotal: 0,
    });
    const [laporanData, setLaporanData] = useState([]);
    const [notifikasiLaporan, setNotifikasiLaporan] = useState([]);
    const [timData, settimData] = useState([]);

    const fetchData = useCallback(async () => {
        try {
            const requests = [
                axios.get(`${baseURL}/api/v1/btnclo/chart_home`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                }),
                axios.get(`${baseURL}/api/v1/btnclo/laporan_home`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                }),
                axios.get(`${baseURL}/api/v1/btnclo/tim`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                }),
            ];

            if (userAccessLevel === '4') {
                requests.push(
                    axios.get(`${baseURL}/api/v1/btnclo/notifikasi_laporan_btnclo`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    })
                );
            }

            const responses = await Promise.all(requests);

            const [
                chartHomeResponse,
                laporanHomeResponse,
                timResponse,
                notifikasiLaporanResponse,
            ] = responses;

            setChartData({
                laporanBerkala: chartHomeResponse.data.laporan_berkala || [],
                laporanInsidentil: chartHomeResponse.data.laporan_insidentil || [],
                laporanTotal: chartHomeResponse.data.total_laporan || 0,
                berkalaTotal: chartHomeResponse.data.kategori_laporan?.berkala || 0,
                insidentilTotal: chartHomeResponse.data.kategori_laporan?.insidentil || 0,
            });

            setLaporanData(laporanHomeResponse.data.laporan || []);
            settimData(timResponse.data.tim || []);

            if (notifikasiLaporanResponse) {
                setNotifikasiLaporan(
                    Array.isArray(notifikasiLaporanResponse.data.notifikasi_laporan?.data)
                        ? notifikasiLaporanResponse.data.notifikasi_laporan.data
                        : []
                );
            }

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    }, [baseURL, token, userAccessLevel]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (loading) {
        return (
            <section className="h-auto">
                <div className="font-mp-bold text-xl text-gray-light">Home</div>
                <div className="mt-8">
                    <div className="flex gap-8 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                        <div className="flex flex-col p-5 h-auto w-full text-base bg-white border border-gray-dark rounded-lg shadow-lg shadow-gray-box col-span-4">
                            <div className="text-center items-center">Loading....</div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 mt-6 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                        <Card className="col-span-2">
                            <div className="text-center items-center">Loading....</div>
                        </Card>
                        <Card className="col-span-2">
                            <div className="text-center items-center">Loading....</div>
                        </Card>
                    </div>
                </div>
            </section>
        );
    }
    
    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Home</div>
            <div className="mt-8">
                <div className="flex flex-col gap-8 laptop:grid laptop:grid-cols-2 laptop:gap-5">
                    <div className="overflow-hidden bg-white laptop:h-full desktop:h-full border border-gray-dark rounded-lg shadow-lg shadow-gray-box col-span-2">
                        <div className="flex flex-col w-full px-8 py-6 sm:p-6">
                            <div className="flex flex-col p-3 gap-10 justify-center items-center laptop:flex-row laptop:justify-evenly">
                                {/* Laporan Berkala */}
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Berkala</div>
                                    <LaporanBerkala
                                        laporanBerkala={chartData.laporanBerkala}
                                        laporanBerkalaTotal={chartData.berkalaTotal}
                                    />
                                </div>
                                {/* Total Laporan */}
                                <div className="flex flex-col gap-2 items-center text-center font-mp-bold">
                                    <div className="text-base whitespace-nowrap">TOTAL LAPORAN</div>
                                    <div className="text-xl">{chartData.laporanTotal}</div>
                                </div>
                                {/* Laporan Insidentil */}
                                <div className="flex flex-col items-center text-base">
                                    <div className="font-mp-bold">Insidentil</div>
                                    <LaporanInsidentil
                                        laporanInsidentil={chartData.laporanInsidentil}
                                        laporanInsidentilTotal={chartData.insidentilTotal}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-8 mt-6 laptop:grid laptop:grid-cols-4 laptop:gap-5">
                    <Card className="col-span-2">
                        <LaporanCard baseURL={baseURL} navigate={navigate} laporan={laporanData} />
                    </Card>
                    {userAccessLevel === '4' && (
                        <Card className="col-span-2">
                            <NotifikasiLaporan baseURL={baseURL} notifikasiLaporan={notifikasiLaporan} />
                        </Card>
                    )}
                    <Card className="col-span-2">
                        <Tim timData={timData} />
                    </Card>
                </div>
            </div>
        </section>
    );
}

const LaporanBerkala = ({ laporanBerkala, laporanBerkalaTotal }) => {
    const defaultData = {
        labels: ['Mingguan', 'Bulanan', 'Tahunan'],
        series: [0, 0, 0],
    };

    // Map the response data to labels and series
    const mapLaporanBerkala = (data) => ({
        'Mingguan': data.mingguan || 0,
        'Bulanan': data.bulanan || 0,
        'Tahunan': data.tahunan || 0
    });

    const mappedData = laporanBerkala ? mapLaporanBerkala(laporanBerkala) : defaultData;

    const labels = Object.keys(mappedData);
    const series = Object.values(mappedData);

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanBerkalaChart laporanBerkala={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanBerkalaTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanBerkalaChart = ({ laporanBerkala }) => {
    const { labels = [], series = [] } = laporanBerkala || {};

    const options = {
        colors: ['#228159', '#DC2626', '#CA8A04'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    return (
        <div className="w-full">
            <ApexCharts options={options} series={series} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanInsidentil = ({ laporanInsidentil, laporanInsidentilTotal }) => {
    const defaultData = {
        labels: ['Informasi', 'Informasi Khusus'],
        series: [0, 0],
    };

    const mapLaporanInsidentil = (data) => ({
        'Informasi': data.informasi || 0,
        'Informasi Khusus': data.informasi_khusus || 0
    });

    const mappedData = laporanInsidentil ? mapLaporanInsidentil(laporanInsidentil) : defaultData;

    const labels = Object.keys(mappedData);
    const series = Object.values(mappedData);

    return (
        <div className="flex w-full p-2 text-base">
            <div className="flex flex-col justify-center items-center mt-4 relative h-[calc(12/12*100%)]">
                <LaporanInsidentilChart laporanInsidentil={{ labels, series }} />
                <div className="absolute flex flex-col items-center justify-center">
                    <div className="flex font-mp-bold text-lg">{laporanInsidentilTotal}</div>
                    <div className="flex text-sm">Laporan</div>
                </div>
            </div>
        </div>
    );
};

const LaporanInsidentilChart = ({ laporanInsidentil }) => {
    const { labels = [], series = [] } = laporanInsidentil || {};

    const options = {
        colors: ['#DC2626', '#CA8A04'],
        labels: labels,
        chart: {
            type: 'donut',
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                customScale: 1.0,
                size: 500
            },
            donut: {
                size: '85%'
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                }
            }
        }]
    };

    return (
        <div className="w-full">
            <ApexCharts options={options} series={series} type="donut" loading="lazy" />
        </div>
    );
};

const LaporanCard = ({ baseURL, navigate, laporan }) => {

    const handleClick = (id) => {
        navigate(`/btnclolaporan/${id}`);
    };

    return (
        <div className="flex flex-col p-4 w-full text-base">
            <div className="flex flex-row justify-between">
                <div className="font-mp-bold">Laporan Terbaru</div>
                <Link to="/btnclolaporan" className="flex flex-row items-center text-[red]">
                    <div className="">Lihat Semua</div>
                    <FontAwesomeIcon icon={faChevronRight} className="h-3 w-3 ml-2"/>
                </Link>
            </div>
            <div className="flex flex-col gap-5 mt-10 laptop:grid laptop:grid-cols-2 laptop:gap-5">
                {laporan.map((item) => (
                    <button key={item.id} className="relative group" onClick={() => handleClick(item.id)}>
                        <img
                            src={baseURL + item.thumbnail}
                            alt="thumbnail"
                            width={0}
                            height={0}
                            sizes="100vw"
                            className="w-full aspect-[16/9] rounded-xl object-cover"
                            loading="lazy"
                        />
                        <div className="absolute rounded-xl inset-x-0 bottom-0 bg-gradient-to-t from-[black]/[0.8] from-30% h-full w-full bg-opacity-80 flex justify-between">
                            <div className="flex flex-row text-white text-start items-end justify-center gap-4 p-4">
                                <div className="">
                                    <div className="font-gt-bold text-sm">{item.jenisLaporan}</div>
                                    <div className="flex flex-row gap-2 text-base">
                                        <p className="font-gt-italic">{item.instansi}</p>
                                    </div>
                                    <div className="text-sm text-white mt-1">{item.tglLaporan}</div>
                                </div>
                            </div>
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};

const NotifikasiLaporan = ({ baseURL, notifikasiLaporan }) => {
    return (
        <div className="p-2 w-full text-base">
            <div className="flex flex-row justify-between">
                <div className="font-mp-bold">Notifikasi Laporan</div>
            </div>
            <div className="mt-6 text-sm overflow-y-auto max-h-80">
                {notifikasiLaporan.map((laporan) => {
                    let timeAgo = "Invalid date";
                    try {
                        if (laporan.created_at) {
                            timeAgo = formatDistanceToNow(new Date(laporan.created_at), {
                                addSuffix: true,
                                locale: id,
                            });
                        }
                    } catch (error) {
                        console.error("Error formatting date:", error);
                    }

                    return (
                        <a
                            key={laporan.id}
                            href={`/btnclolaporan/${laporan.laporan_id}`}
                            className="block hover:bg-gray-100"
                        >
                            <div className="py-4 flex flex-row items-center border-b border-gray-dark">
                                {/* Logo with a perfect circle and blue background */}
                                <div className="flex-shrink-0 rounded-full bg-blue w-10 h-10 flex items-center justify-center overflow-hidden">
                                    <img
                                        src={baseURL + laporan.logo}
                                        alt="User Logo"
                                        className="w-7 h-7 object-contain"
                                    />
                                </div>
                                {/* Text content */}
                                <div className="ml-4 flex flex-col justify-start gap-1 flex-grow">
                                    <span className="text-sm font-medium leading-tight text-gray-800">
                                        {laporan.action_log}
                                    </span>
                                    <span className="text-xs text-gray-500">
                                        {new Date(laporan.created_at).toLocaleDateString('en-GB')} | {timeAgo}
                                    </span>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};


const Tim = ({ timData }) => {
    return (
        <div className="p-2 w-full text-base">
            <div className="flex flex-row justify-between">
                <div className="font-mp-bold">Koordinator & Anggota</div>
            </div>
            <div className="mt-6 text-sm overflow-y-auto max-h-80">
                {timData.map((member) => (
                    <div key={member.id} className="py-3 flex flex-row items-center border-b border-gray-dark">
                        <div className="ml-3 flex flex-col justify-start gap-1">
                            <div className="text-base font-mp-bold">
                                <span>{member.nama}</span>
                            </div>
                            <span className="text-sm">{member.btnclo}</span>
                            <span className="text-sm">{member.kontak}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


export default HomeBtnclo
