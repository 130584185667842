import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { pdf } from '@react-pdf/renderer';
import PdfDocument from './PdfDocument';
import axios from '../../api/axios';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const BtncloLaporanDetail = () => {
    const { id } = useParams();
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;

    const [laporan, setLaporan] = useState(null);
    const [images, setImages] = useState([]);
    const [comments, setComments] = useState([]);
    const [kontenLabels, setKontenLabels] = useState({
        konten_1: 'Laporan Kegiatan/Kronologis',
        konten_2: 'Hasil Kegiatan',
        konten_3: 'Saran dan Rekomendasi',
    });

    // String replacement logic for laporan.nomor
    const replaceWords = (text) => {
        const replacements = {
            "SUMATERA UTARA": "SUMUT",
            "KEPULAUAN RIAU": "KEPRI",
            "D.I. YOGYAKARTA": "DIY",
            "JAWA TIMUR": "JATIM",
            "KALIMANTAN BARAT": "KALBAR",
            "KALIMANTAN UTARA": "KALTARA",
            "KALIMANTAN TIMUR": "KALTIM",
            "SULAWESI UTARA": "SULUT",
        };

        let modifiedText = text;
        Object.entries(replacements).forEach(([key, value]) => {
            const regex = new RegExp(key, 'g'); // Create a global regex to replace all occurrences
            modifiedText = modifiedText.replace(regex, value);
        });

        return modifiedText;
    };

    useEffect(() => {
        const fetchLaporanDetail = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/v1/btnclo/laporan/${id}`, {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                setLaporan(response.data.laporan);
                setImages(response.data.image);
                setComments(response.data.komen);
            } catch (error) {
                console.error('Error fetching laporan detail:', error);
            }
        };

        fetchLaporanDetail();
    }, [baseURL, id, token]);

    useEffect(() => {
        const getKontenLabels = (kategori, jenisLaporan) => {
            if (kategori === 'INSIDENTIL') {
                if (jenisLaporan === 'INFORMASI') {
                    return {
                        konten_1: 'Pendahuluan',
                        konten_2: 'Fakta-fakta',
                        konten_3: 'Rekomendasi',
                    };
                } else if (jenisLaporan === 'INFORMASI KHUSUS') {
                    return {
                        konten_1: 'Perihal',
                        konten_2: 'Fakta-fakta',
                        konten_3: 'Rekomendasi',
                    };
                }
            }
            return {
                konten_1: 'Laporan Kegiatan/Kronologis',
                konten_2: 'Hasil Kegiatan',
                konten_3: 'Rekomendasi',
            };
        };

        if (laporan) {
            setKontenLabels(getKontenLabels(laporan.kategori, laporan.jenisLaporan));
        }
    }, [laporan]);

    const formatContentWithLineBreaks = (content) => {
        if (!content) return content;
        return content.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    const openPDFInNewTab = async () => {
        if (!laporan) return;

        // Generate and sanitize the filename using the modified laporan.nomor
        let fileName = laporan.nomor ? replaceWords(laporan.nomor) : 'document';
        fileName = fileName.replace(/[/\\?%*:|"<>]/g, '').replace(/\s+/g, '_') + '.pdf';

        const pdfBlob = await pdf(
            <PdfDocument laporan={laporan} kontenLabels={kontenLabels} tipe={laporan.tipe} images={images} />
        ).toBlob();

        const blobURL = URL.createObjectURL(pdfBlob);

        const newWindow = window.open();
        newWindow.document.title = fileName;

        newWindow.document.write(`
            <div style="height: 100vh; margin: 0;">
                <iframe 
                    src="${blobURL}" 
                    title="PDF Preview" 
                    style="width: 100%; height: 100%; border: none;"
                >
                    Your browser does not support PDFs. <a href="${blobURL}" download="${fileName}">Download the PDF</a>.
                </iframe>
            </div>
        `);
    };

    if (!laporan) {
        return (
            <div className="flex items-center">
                <div className="mt-8">
                    <div className="flex flex-col laptop:grid laptop:grid-cols-2 gap-4">
                        <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box">
                        <div className="flex flex-col gap-10 text-lg mt-6 laptop:layout">
                            <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-row text-sm gap-3 items-center laptop:text-base">
                                    <div>{String(laporan.created_by)}</div>
                                    <div><FontAwesomeIcon icon={faChevronRight} /></div>
                                    <div>LAPORAN {String(laporan.kategori)}</div>
                                    <div><FontAwesomeIcon icon={faChevronRight} /></div>
                                    <div>{String(laporan.jenisLaporan)}</div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-2">
                                <div className="flex text-xl laptop:text-2xl font-mp-bold">
                                    {laporan.judulLaporan?.toUpperCase()}
                                </div>
                                <div className="flex flex-col text-base">
                                    <div className="flex">Dibuat oleh: {laporan.created_by?.toUpperCase()}</div>
                                    <div className="flex">Pada: {laporan.tglLaporan}</div>
                                </div>
                            </div>

                            {laporan.kategori === 'INSIDENTIL' && laporan.nomor && (
                                <div className="flex flex-col">
                                    <div className="flex font-mp-bold">Nomor Surat:</div>
                                    <div className="mt-4">{replaceWords(laporan.nomor)}</div>
                                </div>
                            )}

                            {laporan.img_flag !== 0 && (
                                <div className="flex items-center object-contain justify-center mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl">
                                    <Carousel
                                        showArrows={true}
                                        showIndicators={true}
                                        infiniteLoop={false}
                                        dynamicHeight={false}
                                        autoPlay={false}
                                        showStatus={false}
                                        showThumbs={false}
                                    >
                                        {images.length > 0 ? (
                                            images.map((image, index) => (
                                                <div key={index}>
                                                    <div className="flex relative bg-cover">
                                                        <img
                                                            src={`${baseURL}${image.image}`}
                                                            alt=""
                                                            width="100%"
                                                            height="auto"
                                                            sizes="100vw"
                                                            className="laptop:w-full aspect-[16/9] rounded-xl object-cover"
                                                            loading="lazy"
                                                            style={{ pointerEvents: 'auto' }}
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <div>
                                                <div className="flex relative bg-cover">
                                                    <img
                                                        src={`${baseURL}${laporan.thumbnail}`}
                                                        alt="Thumbnail"
                                                        width="100%"
                                                        height="auto"
                                                        sizes="100vw"
                                                        className="w-full aspect-[16/9] rounded-xl object-cover"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Carousel>
                                </div>
                            )}

                            <div className="flex flex-col gap-8 mt-10 text-lg text-justify">
                                {laporan.waktu && (
                                    <div className="flex flex-col">
                                        <div className="flex font-mp-bold">Waktu Kegiatan:</div>
                                        <div className="mt-4">{laporan.waktu}</div>
                                    </div>
                                )}

                                {laporan.konten_1 && (
                                    <div className="flex flex-col">
                                        <div className="font-mp-bold">{kontenLabels.konten_1}</div>
                                        <div className="mt-4">{formatContentWithLineBreaks(laporan.konten_1)}</div>
                                    </div>
                                )}

                                {laporan.konten_2 && (
                                    <div className="flex flex-col">
                                        <div className="font-mp-bold">{kontenLabels.konten_2}</div>
                                        <div className="mt-4">{formatContentWithLineBreaks(laporan.konten_2)}</div>
                                    </div>
                                )}

                                {laporan.konten_3 && (
                                    <div className="flex flex-col">
                                        <div className="font-mp-bold">{kontenLabels.konten_3}</div>
                                        <div className="mt-4">{formatContentWithLineBreaks(laporan.konten_3)}</div>
                                    </div>
                                )}
                                <button
                                    onClick={openPDFInNewTab}
                                    className="bg-blue hover:bg-green text-white py-2 px-4 rounded"
                                >
                                    View PDF
                                </button>
                            </div>

                            <CommentsSection
                                komen={comments}
                                laporan_id={laporan.id}
                                dari={String(auth.user.name)}
                                untuk={String(laporan.created_by)}
                                token={token}
                                setComments={setComments}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const CommentsSection = ({ komen = [], laporan_id, dari, untuk, token, setComments }) => {
    const baseURL = useContext(ApiContext);
    const [localComments, setLocalComments] = useState(komen);
    const [newComment, setNewComment] = useState('');
    const currentUser = dari;

    useEffect(() => {
        setLocalComments(komen);
    }, [komen]);

    const handleCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handlePostComment = async (e) => {
        e.preventDefault();

        if (newComment.trim() === '') {
            alert('Komentar tidak boleh kosong');
            return;
        }

        const formData = {
            id: '',
            laporan_id,
            dari: currentUser,
            untuk,
            created_at: new Date().toISOString(),
            komen: newComment
        };

        const apiEndpoint = `${baseURL}/api/v1/btnclo/store_komen`;

        try {
            const response = await axios.post(apiEndpoint, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNewComment('');
            setLocalComments(prevComments => [...prevComments, response.data]);
            setComments(prevComments => [...prevComments, response.data]);
            window.location.reload();
        } catch (error) {
            console.error('Error adding comment:', error.response ? error.response.data : error.message);
        }
    };

    const handleDeleteComment = async (commentId) => {
        const apiEndpoint = `${baseURL}/api/v1/btnclo/delete_komen/${commentId}`;

        try {
            await axios.delete(apiEndpoint, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setLocalComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
            setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
        } catch (error) {
            console.error('Error deleting comment:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div>
            <div className="flex flex-col gap-3">
                <label className="text-lg font-mp-bold mb-2 py-4 border-b border-gray-dark">Komentar</label>
                {localComments.length > 0 ? localComments.map((comment, index) => (
                    <div key={index} className="py-3 flex flex-row items-center">
                        <div className="flex justify-center items-center rounded-full bg-blue p-2 w-auto">
                            <img
                                src={baseURL + comment.logo}
                                alt="User Logo"
                                className="h-5 w-5 rounded-full"
                            />
                        </div>
                        <div className="ml-3 flex flex-col justify-start gap-1">
                            <div className="flex items-center gap-4">
                                <span className="font-bold">{String(comment.created_by)}</span>
                                <span className="text-base ml-2"> {String(comment.created_at)}</span>
                                {comment.created_by === currentUser && (
                                    <FontAwesomeIcon 
                                        icon={faTrash} 
                                        className="text-red h-4 w-4 cursor-pointer"
                                        onClick={() => handleDeleteComment(comment.id)}
                                    />
                                )}
                            </div>
                            <span className="text-lg whitespace-pre-wrap">{String(comment.komen)}</span>
                        </div>
                    </div>
                )) : <div className="py-3">Belum ada komentar</div>}
                <textarea
                    type="text"
                    id="komentar"
                    placeholder="Tambahkan komentar..."
                    className="block w-full h-20 rounded-md border-0 mt-2 px-3 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
                    value={newComment}
                    onChange={handleCommentChange}
                ></textarea>
                <div className="flex justify-end">
                    <button
                        className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg"
                        onClick={handlePostComment}
                    >
                        Post
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BtncloLaporanDetail;