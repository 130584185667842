import React, { useContext, useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from '../context/apiConfig';
import AuthContext from '../context/AuthProvider';
import axios from '../api/axios';
import { formatDistanceToNow } from 'date-fns';
import { id } from 'date-fns/locale';

const Notifikasi = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const userAccessLevel = auth?.user?.access_level;  // Ensure access level is fetched correctly

    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginationLinks, setPaginationLinks] = useState([]);

    // Helper function to determine the correct endpoint
    const getNotificationEndpoint = (baseURL, userAccessLevel, page) => {
        return (userAccessLevel === "4" || userAccessLevel === "5")
            ? `${baseURL}/api/v1/btnclo/notifikasi_btnclo?page=${page}`
            : `${baseURL}/api/notifikasi?page=${page}`;
    };

    const fetchNotifications = useCallback(async (page) => {
        try {
            const endpoint = getNotificationEndpoint(baseURL, userAccessLevel, page);

            console.log('Fetching notifications from endpoint:', endpoint);
            console.log('User Access Level:', userAccessLevel);  // Log user access level for debugging

            const response = await axios.get(endpoint, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setNotifications(response.data.notifikasi.data);
            setCurrentPage(response.data.notifikasi.current_page);
            setTotalPages(response.data.notifikasi.last_page);
            setPaginationLinks(response.data.notifikasi.links);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }, [baseURL, token, userAccessLevel]);

    useEffect(() => {
        if (userAccessLevel) {  // Ensure userAccessLevel is defined before fetching
            fetchNotifications(currentPage);
        }
    }, [fetchNotifications, currentPage, userAccessLevel]);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const renderPagination = () => (
        <div className="flex justify-center mt-4 gap-2">
            {currentPage > 1 && (
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg"
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
            )}
            {paginationLinks
                .filter(link => !link.label.includes('Previous') && !link.label.includes('Next'))
                .map((link, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(parseInt(link.label))}
                        className={`mx-1 px-4 py-2 rounded-lg ${link.active ? 'bg-blue text-white' : 'bg-gray-dark text-white'}`}
                    >
                        {link.label}
                    </button>
                ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="bg-gray-dark text-gray-800 px-4 py-2 rounded-lg"
                >
                    <FontAwesomeIcon icon={faArrowRight} />
                </button>
            )}
        </div>
    );

    const formatDate = (dateString) => {
        return formatDistanceToNow(new Date(dateString), { addSuffix: true, locale: id });
    };

    return (
        <div className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">Notifikasi</div>
            <div className="mt-8">
                <div className="grid grid-cols-2 gap-4">
                    <div className="flex flex-col col-span-2 bg-white laptop:h-full desktop:h-full px-6 py-6 overflow-hidden rounded-lg border border-gray-dark shadow-lg shadow-gray-box">
                        {notifications.map(notification => (
                            <a
                                key={notification.id}
                                href={(userAccessLevel === '4' || userAccessLevel === '5')
                                    ? `/btnclolaporan/${notification.laporan_id}`  // For access level '4' or '5'
                                    : `/laporan/${notification.laporan_id}`}  // For other access levels
                            >
                                <div className="p-3 flex flex-row items-center border-b border-gray-dark">
                                    {/* Logo with a perfect circle and blue background */}
                                    <div className="flex-shrink-0 rounded-full bg-blue w-10 h-10 flex items-center justify-center overflow-hidden">
                                        <img
                                            src={baseURL + notification.logo_dari}
                                            alt="User Logo"
                                            className="w-7 h-7 object-contain"
                                        />
                                    </div>
                                    {/* Text content */}
                                    <div className="ml-4 flex flex-col justify-start gap-1 flex-grow">
                                        <div className="text-sm font-medium leading-tight text-gray-800">
                                            <span className="">{`${notification.dari} mengomentari laporan ${notification.untuk} yang berjudul ${notification.action_log.split('yang berjudul ')[1]}`}</span>
                                        </div>
                                        <span className="text-sm">{new Date(notification.created_at).toLocaleDateString('en-GB')} | {formatDate(notification.created_at)}</span>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            {renderPagination()}
        </div>
    );
};

export default Notifikasi;
