import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../context/AuthProvider';
import { ApiContext } from '../../context/apiConfig';

const InputUserForm = () => {
  const baseURL = useContext(ApiContext);

  const [errors, setErrors] = useState({
    username: '',
    password: ''
  });

  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    password: '',
    satker: '',
    instansi: '',
    access_level: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [satkerOptions, setSatkerOptions] = React.useState([{ value: '', label: '-- Pilih Opsi --' }]);
  const [instansiOptions, setInstansiOptions] = React.useState([{ value: '', label: '-- Pilih Opsi --' }]);

  const { auth } = useContext(AuthContext);
  const token = auth?.accessToken;
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        let poldaEndpoint;
        let instansiEndpoint;
  
        // Determine endpoints based on access level
        if (formData.access_level >= 0 && formData.access_level <= 3) {
          poldaEndpoint = `${baseURL}/api/dropdown_polda`;
          instansiEndpoint = `${baseURL}/api/dropdown_polres_plbn`;
        } else if ((formData.access_level === 4 || formData.access_level === 5)||(formData.access_level === "4" || formData.access_level === "5")) {
          poldaEndpoint = `${baseURL}/api/v1/btnclo/dropdown_polda`;
          instansiEndpoint = `${baseURL}/api/v1/btnclo/dropdown_btnclo`;
        } else {
          setSatkerOptions([{ value: '', label: '-- Pilih Access Level Terlebih Dahulu --' }]);
          setInstansiOptions([{ value: '', label: '-- Pilih Access Level Terlebih Dahulu --' }]);
          return;
        }
  
        // Fetch data from both endpoints
        const [satkerResponse, instansiResponse] = await Promise.all([
          axios.get(poldaEndpoint, {
            headers: { 'Authorization': `Bearer ${token}` },
          }),
          axios.get(instansiEndpoint, {
            headers: { 'Authorization': `Bearer ${token}` },
          }),
        ]);
  
        // Handle satker data (unchanged)
        const satkerData = satkerResponse?.data?.polda || [];
        setSatkerOptions([
          { value: '', label: '-- Pilih POLDA --' },
          ...satkerData.map(polda => ({
            value: polda.polda,
            label: polda.polda,
          })),
        ]);
  
        // Handle instansi data based on access level
        let instansiOptionsData;
        if (formData.access_level >= 0 && formData.access_level <= 3) {
          const instansiData = instansiResponse?.data?.satuan || [];
          instansiOptionsData = instansiData.map(satuan => ({
            value: satuan.instansi,
            label: satuan.instansi,
          }));
        } else if ((formData.access_level === 4 || formData.access_level === 5)||(formData.access_level === "4" || formData.access_level === "5")) {
          const btncloInstansiData = instansiResponse?.data?.btnclo || [];
          instansiOptionsData = btncloInstansiData.map(btnclo => ({
            value: btnclo.btnclo,
            label: btnclo.btnclo,
          }));
        }
  
        // Update instansi options
        setInstansiOptions([{ value: '', label: '-- Pilih Satuan --' }, ...instansiOptionsData]);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
  
        // Set fallback options in case of error
        setSatkerOptions([{ value: '', label: '-- Error Loading POLDA Data --' }]);
        setInstansiOptions([{ value: '', label: '-- Error Loading Satuan Data --' }]);
      }
    };
  
    fetchOptions();
  }, [formData.access_level, token, baseURL]);
  
  const getSatkerOptions = (access_level) => {
    if (access_level === null || access_level === undefined) {
      return [{ value: '', label: '-- Pilih Access Level Terlebih Dahulu --' }];
    }
    return satkerOptions;
  };
  
  const getInstansiOptions = (satker) => {
    if (!satker) {
      return [{ value: '', label: '-- Pilih POLDA Terlebih Dahulu --' }];
    }
    return instansiOptions;
  };

  useEffect(() => {
    if (isEditMode) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get(`${baseURL}/api/user/${id}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          const user = response.data.user;
          setFormData({
            username: user.username || '',
            name: user.name || '',
            email: user.email || '',
            password: '', // Do not pre-fill password
            satker: user.satker || '',
            instansi: user.instansi || '',
            access_level: user.access_level || '',
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };

      fetchUserData();
    }
  }, [id, isEditMode, token, baseURL]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      if (value.trim() === '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          username: 'Username tidak boleh kosong'
        }));
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          username: ''
        }));
      }
    }
    if (name === 'password') {
      if (value.trim() === '' && (!isEditMode || (isEditMode && formData.password.trim() !== ''))) {
        setErrors(prevErrors => ({
          ...prevErrors,
          password: 'Password tidak boleh kosong'
        }));
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          password: ''
        }));
      }
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiEndpoint = isEditMode ? `${baseURL}/api/update_user/${id}` : `${baseURL}/api/register`;

    let hasErrors = false;
    if (formData.username.trim() === '') {
      setErrors(prevErrors => ({
        ...prevErrors,
        username: 'Username tidak boleh kosong'
      }));
      hasErrors = true;
    }
    if (formData.password.trim() === '' && (!isEditMode || (isEditMode && formData.password.trim() !== ''))) {
      setErrors(prevErrors => ({
        ...prevErrors,
        password: 'Password tidak boleh kosong'
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const payload = { ...formData };
      // Remove password from payload if in edit mode and password is empty
      if (isEditMode && formData.password.trim() === '') {
        delete payload.password;
      }

      const response = await axios.post(apiEndpoint, payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('User saved successfully:', response.data);
      navigate('/admin/adminuser');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(prevErrors => ({
          ...prevErrors,
          password: 'Password tidak boleh kosong'
        }));
      }
      console.error('Error saving user:', error.response ? error.response.data : error.message);
    }
  };

  const handleCancel = () => {
    navigate('/admin/adminuser');
  };

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col col-span-2 bg-white h-full px-6 py-6 overflow-hidden border border-gray-dark rounded-lg shadow-lg shadow-gray-box gap-8">
            <div className="text-lg font-bold">{isEditMode ? 'Edit User' : 'Input User'}</div>
            <div className="flex flex-col gap-6 px-4">
              <FormInput
                label="Username"
                type="text"
                name="username"
                value={formData.username}
                handleChange={handleChange}
                errors={errors.username}
              />
              <FormInput label="Fullname" type="text" name="name" value={formData.name} handleChange={handleChange} />
              <FormInput label="Email" type="email" name="email" value={formData.email} handleChange={handleChange} />
              <PasswordInput
                showPassword={showPassword}
                togglePasswordVisibility={togglePasswordVisibility}
                value={formData.password}
                handleChange={handleChange}
                errors={errors.password}
              />
              <FormSelect
                label="Access Level"
                name="access_level"
                value={formData.access_level}
                handleChange={handleChange}
                options={[
                  { value: '', label: '-- Pilih Opsi --' },
                  ...(auth?.user?.access_level === '1'
                    ? [] // Remove "0" if access_level is 1
                    : [{ value: '0', label: '0 - Developer' }]),
                  { value: '1', label: '1 - Admin' },
                  { value: '2', label: '2 - VVIP' },
                  { value: '3', label: '3 - Operator IMBAS' },
                  { value: '4', label: '4 - Koordinator BTNCLO' },
                  { value: '5', label: '5 - Anggota BTNCLO' },
                ]}
              />
              <FormSelect 
                label="POLDA" 
                name="satker" 
                value={formData.satker} 
                handleChange={handleChange} 
                options={getSatkerOptions(formData.access_level)}  
              />
              <FormSelect 
                label="Satuan" 
                name="instansi" 
                value={formData.instansi} 
                handleChange={handleChange} 
                options={getInstansiOptions(formData.satker)} 
              />
            </div>
            <div className="flex flex-row justify-end gap-3 mt-8">
              <button type="button" onClick={handleCancel} className="bg-red text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Cancel
              </button>
              <button type="submit" className="bg-blue hover:bg-green text-white flex justify-center items-center h-12 w-28 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const FormInput = ({ label, type, name, value, handleChange, errors }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <input
        type={type}
        name={name}
        id={name}
        autoComplete="given-name"
        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
        value={value}
        onChange={handleChange}
      />
      {errors && <span className="text-red text-sm">{errors}</span>}
    </div>
  </div>
);

const PasswordInput = ({ showPassword, togglePasswordVisibility, value, handleChange, errors }) => (
  <div className="flex flex-col relative">
    <label htmlFor="password" className="block text-base leading-6 text-gray">Password</label>
    <div className="mt-2 relative">
      <input
        type={showPassword ? 'text' : 'password'}
        name="password"
        id="password"
        autoComplete="new-password"
        className={`block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6 ${errors ? 'ring-red' : ''}`}
        value={value}
        onChange={handleChange}
      />
      <FontAwesomeIcon
        icon={showPassword ? faEyeSlash : faEye}
        className="text-blue h-5 w-5 absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
        onClick={togglePasswordVisibility}
      />
      {errors && <span className="text-red text-sm">{errors}</span>}
    </div>
  </div>
);

const FormSelect = ({ label, name, value, handleChange, options }) => (
  <div className="flex flex-col">
    <label htmlFor={name} className="block text-base leading-6 text-gray">{label}</label>
    <div className="mt-2">
      <select
        id={name}
        name={name}
        autoComplete="given-name"
        className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 ring-black placeholder:text-gray focus:ring-1 focus:ring-inset focus:ring-blue sm:text-sm sm:leading-6"
        value={value}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  </div>
);

export default InputUserForm;

