import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../context/apiConfig';
import AuthContext from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import Tiles from '../../components/reusable/Tiles';

const Btnclo = () => {
    const baseURL = useContext(ApiContext);
    const { auth } = useContext(AuthContext);
    const token = auth?.accessToken;
    const navigate = useNavigate();

    const [polda, setPolda] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/v1/btnclo/dropdown_polda`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const processedData = response.data.polda.map(polda => ({
                    fullName: polda.polda, // Keep the original full name
                    displayName: polda.polda.replace("BTNCLO POLDA ", ""), // Replace "POLDA" with "BTNCLO" for display
                    logo: polda.logo
                }));

                setPolda(processedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token, baseURL]);

    const handleTileClick = (item) => {
        navigate(`/btnclo/${encodeURIComponent(item.fullName)}`, { state: { poldaName: item.fullName } });
    };

    return (
        <section className="h-auto">
            <div className="font-mp-bold text-xl text-gray-light">BTNCLO</div>
            <div className="mt-8 font-mp-bold">
                <div className="grid grid-cols-2 gap-4 laptop:grid laptop:grid-cols-5">
                    {polda.map((item, index) => (
                        <div
                            key={index}
                            className="cursor-pointer"
                            onClick={() => handleTileClick(item)}
                        >
                            <Tiles>
                                <div className="flex items-center justify-center">
                                    <img
                                        src={`${baseURL}${item.logo}`}
                                        alt={item.fullName}
                                        className="h-3/12 w-3/12 object-contain"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="flex mt-4">{item.displayName}</div>
                            </Tiles>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Btnclo;
